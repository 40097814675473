import React from "react"
import { Helmet } from "react-helmet"
import config from "../../../site.config"

/** components */
import Layout from "../../components/Layout"
import { theme } from "../../components/Theme"

/** static sections */
import NewsletterSignup from "../../components/static-sections/NewsletterSignupSection"
import CompareSection from "../../components/static-sections/CompareSection"

/** dynamic sections */
import HeroTopSection from "../../components/dynamic-sections/HeroTopSection"
import RibbonSection from "../../components/dynamic-sections/RibbonSection"
import SixImageSection from "../../components/dynamic-sections/SixImageSection"
import GalleryImageSection from "../../components/dynamic-sections/GalleryImageSection"
import AwardsSection from "../../components/dynamic-sections/AwardsSection"
import GoogleMapSection from "../../components/dynamic-sections/GoogleMapSection"
import HeroRightSection from "../../components/dynamic-sections/HeroRightSection"
import FourImageSmallSection from "../../components/dynamic-sections/FourImageSmallSection"

/** color theme */
const colorPalette = theme.colorPalettes.blue

/** svg */
import NorhartIcon from "../../../assets/norhart-icon.svg"
import ApartmentIcon from "../../../assets/apartment-icon.svg"
import BadgeCheckIcon from "../../../assets/badge-check-icon.svg"
import NorhartDarkCircleIcon from "../../../assets/norhart-dark-circle-icon.svg"

/** props */
interface Props {
  data: any
}

/** const */
const BlaineHistoryPage: React.FC<Props> = (props) => {
  const ldJson = {
    "@context": "http://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        item: {
          "@id": "/locations/blaine/",
          name: "Blaine Location | Norhart",
          image: `${config.siteMetadata.siteUrl}/locations/blaine/blaine-hero-open-graph.png`,
        },
      },
    ],
  }

  return (
    <Layout
      title="Blaine Apartment Locations | Norhart"
      description="Blaine, MN is a city in Anoka and Ramsey counties in the State of Minnesota. Blaine is located 18 miles from Minneapolis, and the population is 57,186"
      keywords="norhart, luxury, innovative, smart, technology, apartment, apartments, building, buildings, mn, minnesota, minneapolis, st paul, rent, rentals, resident, residents, forest lake, blaine, circle pines, lexington, coon rapids, Oakdale, encore apartments, lexington lofts apartments, Oakdale apartments, gateway green apartments, mill pond apartments, birchview apartments, legacy pointe apartments, heritage heights apartments, springwood apartments, greystone apartments, northbrook apartments, construction, norhart construction, lean construction"
      imageTwitter="/locations/blaine/blaine-hero-twitter-card.png"
      imageOpenGraph="/locations/blaine/blaine-hero-open-graph.png"
      colorPalette={colorPalette}
    >
      <HeroTopSection
        title="Blaine, MN"
        subtitle="Experience What Blaine Has To Offer"
        imageTitle="Experience What Blaine Has To Offer"
        image="/locations/blaine/blaine-location-hero.png"
        colorPalette={colorPalette}
      />

      <RibbonSection
        title="Blaine Apartments"
        tagLine="Apartments that will improve your life"
        colorPalette={colorPalette}
      />

      <Helmet>
        <script type="application/ld+json">{JSON.stringify(ldJson)}</script>
      </Helmet>

      <GoogleMapSection
        icon={NorhartDarkCircleIcon}
        iconTitle="Blaine, MN"
        title="Norhart Invite's You To Explore Blaine"
        subtitle="Blaine is a city in Anoka and Ramsey counties in the State of Minnesota. Blaine located 18 miles from Minneapolis and the population is 57,186"
        query="Lexington,+MN+Lexington Lofts"
        colorPalette={colorPalette}
      />

      <SixImageSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        icon={NorhartDarkCircleIcon}
        iconTitle="Norhart Blaine"
        title="Live Work and Grow in Blaine"
        subtitle="Did you know that Blaine is one of the fastest-growing cities in Minnesota and one of the Top 100 places to live? Yup, it is! Visit our vibrant, growing community in Blaine!"
        imageTitleOne="TPC Twin Cities"
        imageOneText="Located in the center of the city, in the beautiful countryside, is this private golf club designed by Arnold Palmer himself. This 18-hole golf course of 235 acres, provides each golfer breathtaking views you must see for yourself."
        imageOne="/locations/blaine/tpc-golf.png"
        imageOneAlt="Blaine TPC Twin Cities"
        imageOnePill=""
        imageTitleTwo="Anoka County-Blaine Airport"
        imageTwoText="Janes Field supports more than 74,000 takeoffs and landings annually. They provide infrastructure for the region’s corporate and leisure aviation needs, relieving congestion at Minneapolis-St. Paul International Airport."
        imageTwo="/locations/blaine/airport.png"
        imageTwoAlt="Blaine Anoka County-Blaine Airport"
        imageTwoPill=""
        imageTitleThree="National Sports Center"
        imageThreeText="The National Sports Center offers Olympic-class sporting arenas, activities and events, and even training facilities. There is always something happening that you can take part in, go check it out!"
        imageThree="/locations/blaine/national-sports-center.png"
        imageThreeAlt="Blaine National Sports Center"
        imageThreePill=""
        imageTitleFour="Aquatore Park"
        imageFourText="Aquatore park has it all. There are two large picnic shelters, indoor restrooms, grills, a playground, a bike trail, a baseball diamond, a basketball court, a football field, and a dog park. This park is just great!"
        imageFour="/locations/blaine/aquatore-park.png"
        imageFourAlt="Blaine Aquatore Park"
        imageFourPill=""
        imageTitleFive="Northtown Mall"
        imageFiveText="When the mall opened in 1972, there were 33 stores. Today, there are more than 100 stores and restaurants as well as a food court found within the mall. The Northtown mall is the premier shopping destination in Blaine, MN."
        imageFive="/locations/blaine/northtown-mall.png"
        imageFiveAlt="Blaine Northtown Mall"
        imageFivePill=""
        imageTitleSix="Blaine High School"
        imageSixText="Blaine is a part of the Anoka-Hennepin School District. Anoka-Hennepin schools are split into three levels: Elementary School, Middle School, and High School. The high school was opened in 1972 and has continued to grow each year. Go Bengals!"
        imageSix="/locations/blaine/high-school.png"
        imageSixAlt="Blaine Blaine High School"
        imageSixPill=""
        pillActive=""
        colorPalette={colorPalette}
        buttonName="Learn More"
        buttonUrl="https://www.blainemn.gov"
        buttonBackground="#FFFFFF"
        buttonText="#111111"
      />

      <GalleryImageSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentTop}
        icon={ApartmentIcon}
        iconTitle="Now Open"
        title="Hello. Lexington Lofts"
        subtitle="We invite you to be a part of our newest apartment community in Blaine, MN. At Norhart our teams have designed and built an exceptional living experience from the top down just for you!"
        imageTitleOne="Lexington Lofts Image 1"
        imageOne="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-1.webp"
        imageTitleTwo="Lexington Lofts Image 2"
        imageTwo="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-2.webp"
        imageTitleThree="Lexington Lofts Image 3"
        imageThree="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-3.webp"
        imageTitleFour="Lexington Lofts Image 4"
        imageFour="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-4.webp"
        imageTitleFive="Lexington Lofts Image 5"
        imageFive="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-5.webp"
        imageTitleSix="Lexington Lofts Image 6"
        imageSix="/apartments/gallery/lexington-lofts/norhart-lexington-lofts-6.webp"
        buttonUrl="https://www.norhart.com/blaine/lexington-lofts/"
        colorPalette={colorPalette}
        badge={true}
      />

      <FourImageSmallSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        icon={BadgeCheckIcon}
        title="Apartment Nice Little Extras"
        subtitle="We invite you to choose a Norhart apartment as your next home. We have carefully crafted and curated a living experience that will enhance your life in every-way possible."
        imageOnePrice="$55/mo"
        imageHeaderOne="Add-on"
        imageTitleOne="Pet Friendly"
        imageSubTitleOne="We know how important your furry friends are :-)"
        imageOne="/apartments/norhart-apartments-pet-friendly.webp"
        imageOneAlt="Norhart Apartments are Pet Friendly"
        imageTwoPrice="$75+/mo"
        imageHeaderTwo="Add-on"
        imageTitleTwo="Parking"
        imageSubTitleTwo="With underground parking no more cold mornings!"
        imageTwo="/apartments/norhart-apartments-underground-parking.webp"
        imageTwoAlt="Norhart Undergroud Parking"
        imageThreePrice="Free"
        imageHeaderThree="Limited"
        imageTitleThree="Outdoor Balcony"
        imageSubTitleThree="Relax outside and soak up the sun and atmosphere."
        imageThree="/apartments/norhart-apartments-outdoor-balcony.webp"
        imageThreeAlt="Norhart Apartment Balcony"
        imageFourPrice="$50+/mo"
        imageHeaderFour="Limited"
        imageTitleFour="Internet"
        imageSubTitleFour="Plug and play with our in-unit WIFI $50+/mo."
        imageFour="/apartments/norhart-apartments-smart-home.webp"
        imageFourAlt="Norhart Smart Apartment App"
        priceActive={true}
        colorPalette={colorPalette}
        imageExtraOne="/apartments/gallery/extras/extras-1.webp"
        imageTitleExtraOne="Norhart Extra Image 1"
        imageExtraTwo="/apartments/gallery/extras/extras-2.webp"
        imageTitleExtraTwo="Norhart Extra Image 2"
        imageExtraThree="/apartments/gallery/extras/extras-3.webp"
        imageTitleExtraThree="Norhart Extra Image 3"
        imageExtraFour="/apartments/gallery/extras/extras-4.webp"
        imageTitleExtraFour="Norhart Extra Image 4"
        imageExtraFive="/apartments/gallery/extras/extras-5.webp"
        imageTitleExtraFive="Norhart Extra Image 5"
        imageExtraSix="/apartments/gallery/extras/extras-6.webp"
        imageTitleExtraSix="Norhart Extra Image 6"
        asoEffect="fade"
        buttonName="View Apartments"
        buttonUrl="/apartments/"
      />

      <HeroRightSection
        backgroundColorOne={colorPalette.cardAccentTop}
        backgroundColorTwo={colorPalette.cardAccentBottom}
        fontTextColor="#2f5265"
        titleTopLeft="Norhart Living"
        badgeTitle="Great Floorplans"
        title="Beautiful Living Areas"
        subtitle="Choose from one, two, or three-bedroom floor-plans. We carefully designed each space to be spacious, functional, and luxurious. Just for you!"
        imageTitle="Norhart Apartment Floor Plans"
        image="/apartments/norhart-apartments-floor-plan.png"
        colorPalette={colorPalette}
        colSizeOne={6}
        colSizeTwo={6}
        bottomMargin={5}
        asoEffect="fade"
      />

      <CompareSection />

      <HeroRightSection
        backgroundColorOne={colorPalette.heroTop}
        backgroundColorTwo={colorPalette.heroBottom}
        fontTextColor="#FFFFFF"
        titleTopLeft="History"
        badgeTitle="City History"
        title="Blaine, MN"
        subtitle="Blaine in Anoka County, Minnesota, has a fascinating history that will captivate all audiences, both young and old."
        imageTitle="Blaine MN History"
        image="/history/blaine-history.png"
        buttonName="Learn More"
        buttonUrl="/history/blaine/"
        colorPalette={colorPalette}
        colSizeOne={4}
        colSizeTwo={8}
        bottomMargin={0}
        asoEffect="fade"
      />

      <NewsletterSignup colorPalette={colorPalette} />

      <AwardsSection
        header="Blaine Apartments"
        title="We Build More Than Apartments"
        subtitle="We have been called out for being an innovator, setting the bar high, and pushing the industry in a new direction. That's just who we are. Experience the Norhart difference for yourself!"
        colorPalette={colorPalette}
      />
    </Layout>
  )
}

/** export */
/** export */
export default BlaineHistoryPage
